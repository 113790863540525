<template>
  <p class="current-step">{{ currentStep }}</p>

  <div class="step-indicators">
    <slot name="step-indicators"></slot>
  </div>

  <slot></slot>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: String,
      default: '',
    }
  },
  emits: ['profileImageUploaded', 'profileImageDeleted', 'profileUpdated'],
};
</script>

<style scoped>
</style>
